<template>
  <div class="mr-4 mb-1">
    <template v-if="fieldDetails.inputType == 'number'">
      <TextField
        :fieldDetails="fieldDetails"
        :cancelPendingChanges="cancelPendingChanges"
        :currentFieldValue="this.resourceModel[this.fieldDetails.fieldName]"
        @updatePendingChanges="updatePendingChanges(...arguments)"
      ></TextField>
    </template>
    <template v-else-if="fieldDetails.inputType == 'textI18n'">
      <TextI18nField
        @updatePendingChanges="updatePendingChanges(...arguments)"
        :fieldDetails="fieldDetails"
        :shortLocales="shortLocales"
        :resourceModel="resourceModel"
        :cancelPendingChanges="cancelPendingChanges"
      ></TextI18nField>
    </template>
    <template v-else-if="fieldDetails.inputType == 'select'">
      <SelectField
        @updatePendingChanges="updatePendingChanges(...arguments)"
        :fieldDetails="fieldDetails"
        :selectOptions="selectOptions"
        :currentFieldValue="this.resourceModel[this.fieldDetails.fieldName]"
        :cancelPendingChanges="cancelPendingChanges"
      ></SelectField>
    </template>

    <!-- <template v-else-if="fieldDetails.inputType == 'date'">
      <DateField @updatePendingChanges="updatePendingChanges(...arguments)" :fieldDetails="fieldDetails" :currentFieldValue="this.resourceModel[this.fieldDetails.fieldName]" :fieldOptions="fieldOptions" :cancelPendingChanges="cancelPendingChanges"></DateField>
    </template>
    <template v-else-if="fieldDetails.inputType == 'trueFalse'">
      <SwitchField @updatePendingChanges="updatePendingChanges(...arguments)" :fieldDetails="fieldDetails" :cancelPendingChanges="cancelPendingChanges" :switchFieldValue="this.resourceModel[this.fieldDetails.fieldName]"></SwitchField>
    </template>-->
    <template v-else>
      <TextField
        :fieldDetails="fieldDetails"
        :cancelPendingChanges="cancelPendingChanges"
        :currentFieldValue="this.resourceModel[this.fieldDetails.fieldName]"
        @updatePendingChanges="updatePendingChanges(...arguments)"
      ></TextField>
    </template>
  </div>
</template>
<script>
import TextField from "@jsroot/apps/booster-pages/editor-parts/forms/TextField"
import TextI18nField from "@jsroot/apps/booster-pages/editor-parts/forms/TextI18nField"
// import SwitchField from '@jsroot/apps/booster-pages/editor-parts/forms/SwitchField'
import SelectField from "@jsroot/apps/booster-pages/editor-parts/forms/SelectFieldWR"
// import DateField from '@jsroot/apps/booster-pages/editor-parts/forms/DateField'
// import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    TextI18nField,
    // SwitchField,
    TextField,
    SelectField
    // DateField
  },
  props: [
    "resourceModel",
    "fieldDetails",
    "allSelectOptions",
    "cancelPendingChanges"
  ],
  data() {
    return {
      // Below is used by TextI18nField
      // to show an input per locale for
      // each single field
      shortLocales: ["en"]
      // shortLocales: ["en","es"]
    }
  },
  computed: {
    selectOptions() {
      // if (this.allSelectOptions[this.fieldDetails.fieldName]) {
      //   debugger
      // } else {

      // }
      return this.allSelectOptions[this.fieldDetails.fieldName]
      //  ['Foo', 'Bar', 'Fizz', 'Buzz']
    }
    // currentFieldValue: {
    //   get() {
    //     this.fieldDetails.originalValue = _.clone(this.resourceModel[this.fieldDetails.fieldName])
    //     return this.fieldDetails.originalValue
    //   },
    //   // // setter
    //   set(newValue) {
    //     this.fieldDetails.newValue = newValue
    //     this.$store.dispatch('updatePendingChanges', this.fieldDetails, newValue)
    //   }
    // }
  },
  methods: {
    // feb 2019 - just noticed that if I $emit('updatePendingChanges' from child component
    // like SelectField, this will get triggered even though I have not explicitly
    // bound it in the component declaration
    updatePendingChanges(fieldDetailsWithNewValue) {
      this.$emit("updatePendingChanges", fieldDetailsWithNewValue)
      // Apr 2020 - Previously went to a lot of trouble to not change values directly on
      // the resourceModel directly.  Now it doesn't really matter but haven't
      // rolled back all the extra code in the individual form fields yet..
      this.resourceModel[this.fieldDetails.fieldName] =
        fieldDetailsWithNewValue.newValue
    }
  }
}
</script>
