<template>
  <section>
    <v-container pa-0>
      <v-layout row wrap justify-center class="my-0">
        <v-flex xs12></v-flex>
        <v-flex xs12>
          <v-card class>
            <v-card-text>
              <v-layout row>
                <v-flex xs12 sm12 offset-sm0>
                  <ImgUploaderUppy
                    modelClass="tt"
                    modelId="tid"
                    collection="thumbnail"
                    @imageuploaded="imageuploaded"
                    text="Uppy"
                    :imageUploadUrl="imageUploadUrl"
                  ></ImgUploaderUppy>
                  <!--                   <v-btn color="" class="accent">
                    <ImgUploaderSimple @imageuploaded="imageuploaded" :text="$ft('client_admin.editor.addPhoto')" :imageUploadUrl="imageUploadUrl"></ImgUploaderSimple>
                  </v-btn>-->
                </v-flex>
              </v-layout>
              <v-layout wrap row></v-layout>
              <!-- below dive had been a draggable element which allowed sorting -->
              <div class="layout wrap row">
                <v-flex
                  pa-2
                  xs6
                  sm3
                  v-for="imageDetails in imagesList"
                  :key="imageDetails.id"
                  class="item"
                >
                  <ImgForEditing
                    :listingModelName="currentPropForEditing.listing_model_name"
                    allowAltEditing="true"
                    :imageDetails="imageDetails"
                    @deletePhoto="deletePhoto"
                  ></ImgForEditing>
                </v-flex>
              </div>
              <v-layout row>
                <v-flex xs12 sm12 offset-sm0></v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12></v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
// import draggable from 'vuedraggable'
// Apr 2020 - might implement sorting by draggable later
import ImgUploaderSimple from "@jsroot/shared/ImgUploaderSimple"
import ImgUploaderUppy from "@jsroot/shared/ImgUploaderUppy"
import ImgForEditing from "@jsroot/shared/ImgForEditing"
export default {
  components: {
    ImgUploaderUppy,
    // VueCoreImageUpload,
    // draggable,
    ImgForEditing
    // ImgUploaderSimple
  },
  props: {
    currentPropForEditing: {
      type: Object,
      default: () => {}
    },
    currentPropEditorFields: {
      type: Object,
      default: () => {}
    }
  },
  // watch: {
  //   $route(to, from) {
  //     this.$store.dispatch("loadPropForEditing", to.params)
  //   }
  // },
  // mounted: function() {
  //   this.$store.dispatch("loadPropForEditing", this.$route.params)
  // },

  computed: {
    imagesList: {
      get() {
        // return this.currentPropForEditing.prop_photos
        // return this.$store.state.listingsEditStore.propImages
        return []
      },
      set(value) {
        // this.$store.commit('updatePropImages', value)
        // this.$store.dispatch("updatePhotosOrder", value)
      }
    },
    // currentPropForEditing() {
    //   return this.$store.state.listingsEditStore.currentPropForEditing
    // },
    imageUploadUrl() {
      // return this.$store.getters.getListingPhotoUploadUrl(
      //   this.$route.params.listingModel
      // )
      return "/api_admin/v2/" + "propSlug" + "/" + this.currentPropForEditing.id + "/photo"
    }
  },
  methods: {
    imageuploaded(newPhotosList) {
      debugger
      this.$store.commit("setPropImages", { result: newPhotosList })
    },
    // imagechanged() {},
    // imageuploading() {
    //   this.upLoadingDialog = true
    // },
    deletePhoto(imageDetails) {
      var deleteParams = {
        photo_id: imageDetails.id
      }
      this.$store.dispatch("deletePropRentalPhoto", deleteParams)
      // this.confirmDialog = false
    }
  },
  data() {
    return {
      // uploadData: {
      // },
      // uploadSuccess: false,
    }
  }
}
</script>
<style>
.del-photo-btn {
  border-radius: 20px;
}

.prop-img-edit {
  width: 100%;
}
</style>