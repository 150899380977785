import { render, staticRenderFns } from "./ImgUploaderUppy.vue?vue&type=template&id=5b3e7b86&"
import script from "./ImgUploaderUppy.vue?vue&type=script&lang=js&"
export * from "./ImgUploaderUppy.vue?vue&type=script&lang=js&"
import style0 from "@uppy/core/dist/style.css?vue&type=style&index=0&lang=css&"
import style1 from "@uppy/status-bar/dist/style.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFlex,VLayout})
