<template>
  <v-card>
    <v-layout v-if="editModeOn" row wrap justify-center class="my-0">
      <v-flex justify-center xs12>
        <v-card>
          <v-text-field
            rows="2"
            :multi-line="true"
            name="fieldDetails.fieldName"
            label="English"
            :autofocus="true"
            v-model="imageDetails.alt_text_en"
          ></v-text-field>
          <v-divider></v-divider>
          <v-text-field
            rows="2"
            :multi-line="true"
            name="fieldDetails.fieldName"
            type="type"
            label="Spanish"
            :autofocus="false"
            v-model="imageDetails.alt_text_es"
          ></v-text-field>
        </v-card>
      </v-flex>
      <v-flex justify-center xs12>
        <div>
          <v-chip>
            <a @click="savePropImgText">save</a>
          </v-chip>
          <v-chip>
            <a @click="editModeOn = !editModeOn">cancel</a>
          </v-chip>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap justify-center class="my-0">
      <v-flex justify-center xs12>
        <div class="text-sm-center" style="margin-bottom: -45px;">
          <v-btn @click="promptPhotoDelete" icon>
            <span class="del-photo-btn group pa-2 white">
              <v-icon>delete_forever</v-icon>
            </span>
          </v-btn>
        </div>
        <img v-lazy="imageDetails.image.small_fill.url" class="prop-img-edit" />
        <div></div>
      </v-flex>
      <!-- <v-flex v-if="allowAltEditing" justify-center pl-1 xs6>
        <div style="overflow: scroll;height: 140px;">
          <div class style>
            <span style="font-weight: bold;">EN:</span>
            {{imageDetails.alt_text_en }}
          </div>
          <v-divider></v-divider>
          <div class style>
            <span style="font-weight: bold;">ES:</span>
            {{imageDetails.alt_text_es }}
          </div>
        </div>
      </v-flex>-->
      <v-flex xs12>
        <div class="text-xs-center">
          <v-chip v-if="imageDetails.file_size">{{formatBytes(imageDetails.file_size,2)}}</v-chip>
          <v-chip v-if="imageDetails.height">{{imageDetails.width}} x {{imageDetails.height}}</v-chip>
          <v-chip v-if="imageDetails.image">
            <a :href="imageDetails.image.url">view</a>
          </v-chip>
          <!-- <v-chip v-if="allowAltEditing">
            <a @click="editModeOn = !editModeOn">edit</a>
          </v-chip>-->
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap row>
      <v-dialog v-model="confirmDialog" width="200">
        <v-card>
          <v-card-text>Are you sure?</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="green" flat @click="deletePhoto()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      editModeOn: false,
      confirmDialog: false
    }
  },
  components: {},
  props: ['imageDetails', 'allowAltEditing', 'listingModelName'],
  computed: {},
  methods: {
    deletePhoto() {
      this.$emit('deletePhoto', this.imageDetails)
      // this.$store.dispatch('deletePhoto', deleteParams)
      this.confirmDialog = false
    },
    promptPhotoDelete() {
      this.confirmDialog = true
    },
    savePropImgText() {
      // TODO - renable this and use listingModelName to calculate endpoint url
      this.$store.dispatch('updatePhotoText', this.imageDetails)
      this.editModeOn = false
    },
    // might move below to a helper if I need it elsewhere
    formatBytes(bytes, decimals) {
      if (bytes == 0) return '0 Bytes'
      var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
  }
}
</script>
<style scoped>
.chip {
  height: 15px;
  padding-bottom: 6px;
}

.chip .chip__content {
}
</style>