<template>
  <v-dialog max-width="290" v-model="showModal">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="purple--text title-details">
        New Lead Page
        <!-- <v-icon>mdi-thumb-up</v-icon> -->
        <v-icon right dark>mdi-plus-box</v-icon>
      </v-btn>
    </template>
    <v-card class="inmo-card px-0 py-0">
      <v-card-text>
        <v-layout wrap row>
          <p class="mt-5" v-if="formSuccess.length">
            <v-alert @input="reloadLocation" outlined color="success" dismissible>{{ formSuccess }}</v-alert>
          </p>
          <v-flex v-else text-xs-center xs12>
            <v-flex xs12 sm12 offset-sm0>
              <template v-if="formSending">
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </template>
            </v-flex>
            <v-form
              v-model="formValid"
              ref="addListingForm"
              :lazy-validation="true"
              @submit.prevent="createBoosterPage"
            >
              <div class="pt-3">Enter a title for the new page you want to create</div>
              <div>
                <v-text-field
                  required
                  autofocus
                  label="New Page Title"
                  :rules="validationRules"
                  v-model="newPageParams['page_title_en']"
                ></v-text-field>
              </div>
              <div>
                <v-btn type="submit" color="#9acd32" class="white--text">
                  Create
                  <!-- <v-icon right dark>save</v-icon> -->
                </v-btn>
              </div>
              <p class="pt-3" v-if="formErrors.length">
                <template v-for="(error, index) in formErrors">
                  <v-alert
                    outlined
                    color="error"
                    :key="index"
                    icon="warning"
                    :value="true"
                  >{{error}}</v-alert>
                </template>
              </p>
            </v-form>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap row>
        </v-layout>-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style>
</style>
<script>
// import _ from 'lodash'
// import apiPublicService from "@jsroot/services/api-public"
export default {
  components: {},
  props: {
    formErrors: {
      type: Array,
      default: () => []
    },
    formSending: {
      type: Boolean,
      default: () => false
    },
    formSuccess: {
      type: String,
      default: () => ""
    }
  },
  mounted: function() {
    // this.$store.dispatch('loadSalesSearchFields')
  },
  data() {
    return {
      formValid: false,
      showModal: false,
      newPageParams: {},
      validationRules: [
        v => !!v || "Title for page is required"
        // v =>
        //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        //   "E-mail must be valid"
      ]
      // newPageParams: {}
    }
  },
  watch: {},
  computed: {
    // createEnabled() {
    //   return parseInt(this.resultsForAlertCount) < 51
    // },
  },
  methods: {
    createBoosterPage() {
      if (!this.$refs.addListingForm.validate()) {
        // this.$refs.addListingForm.validate()
        // in case nothing has been typed in, above will display error messages
        return
      }
      this.$emit("runAddPage", this.newPageParams)
    },
    closeModal() {
      this.showModal = false
      this.$emit("cleanupAddListing")
    },
    reloadLocation() {
      location.reload()
    },
    // modalActivated() {},
    // closeModal() {
    //   this.showModal = false
    //   // this.$emit("hideCurrentModal")
    // }
  }
}
</script>
<style>
</style>