<template>
  <section>
    <v-container pa-0 grid-list-xl>
      <v-layout row wrap justify-center class="my-0">
        <v-flex py-0 xs12>
          <v-card class>
            <v-card-title primary-title>
            </v-card-title>
            <v-card-text>
              <form @submit.prevent="triggerPropUpdate">
                <v-layout row>
                  <FormSubmitter
                    :hasPendingChanges="hasPendingChanges"
                    v-on:changesCanceled="changesCanceled"
                  ></FormSubmitter>
                </v-layout>
                <v-layout wrap row>
                  <v-flex xs12>
                    <template
                      v-for="(fieldDetails, index) in currentPropEditorFields.mainInputFields1"
                    >
                      <FieldResolver
                        :key="index"
                        v-on:updatePendingChanges="updatePendingChanges(...arguments)"
                        :fieldDetails="fieldDetails"
                        :fieldOptions="fieldOptions"
                        :cancelPendingChanges="cancelPendingChanges"
                        v-bind:resourceModel="currentPropForEditing"
                      ></FieldResolver>
                    </template>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <FormSubmitter
                    :hasPendingChanges="hasPendingChanges"
                    v-on:changesCanceled="changesCanceled"
                  ></FormSubmitter>
                </v-layout>
              </form>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
// // import TextField from "@jsroot/apps/booster-pages/editor-parts/forms/TextField"
import FormSubmitter from "@jsroot/apps/booster-pages/editor-parts/forms/FormSubmitter"
import FieldResolver from '@jsroot/apps/booster-pages/editor-parts/forms/FieldResolver'
import {dirtyChecker} from '@jsroot/mixins/dirty-checker'
export default {
  mixins: [dirtyChecker],
  components: {
    FormSubmitter,
    // TextField,
    FieldResolver
  },
  // metaInfo() {
  //   return {
  //     title: this.currentPropForEditing.title
  //   }
  // },
  props: {
    currentPropForEditing: {
      type: Object,
      default: () => {}
    },
    currentPropEditorFields: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // hasPendingChanges: false,
      // dirtyFields: {},
      // moved above 2 to dirtyChecker mixin
      fieldOptions: {},
      cancelPendingChanges: null
    }
  }
}
</script>
<style>
</style>
