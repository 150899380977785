<template>
  <section>
    <v-container pa-0 grid-list-xl>
      <v-layout row wrap justify-center class="my-0">
        <v-flex py-0 xs12>
          <v-card class>
            <v-card-title primary-title></v-card-title>
            <v-card-text>
              <form @submit.prevent="triggerPropUpdate">
                <v-layout row>
                  <FormSubmitter
                    :hasPendingChanges="hasPendingChanges"
                    v-on:changesCanceled="changesCanceled"
                  ></FormSubmitter>
                </v-layout>
                <v-layout wrap row>
                  <v-flex xs12 sm3>
                    <template
                      v-for="(fieldDetails, index) in currentPropEditorFields.mainInputFields1"
                    >
                      <FieldResolver
                        :key="index"
                        v-on:updatePendingChanges="updatePendingChanges(...arguments)"
                        :fieldDetails="fieldDetails"
                        :fieldOptions="fieldOptions"
                        :cancelPendingChanges="cancelPendingChanges"
                        v-bind:resourceModel="currentPropForEditing"
                      ></FieldResolver>
                    </template>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <template
                      v-for="(fieldDetails, index) in currentPropEditorFields.mainInputFields2"
                    >
                      <FieldResolver
                        :key="index"
                        v-on:updatePendingChanges="updatePendingChanges(...arguments)"
                        :fieldDetails="fieldDetails"
                        :fieldOptions="fieldOptions"
                        :cancelPendingChanges="cancelPendingChanges"
                        v-bind:resourceModel="currentPropForEditing"
                      ></FieldResolver>
                    </template>
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <map-picker
                      @updatePropAddress="updatePropAddress"
                      style="min-height: 600px;"
                      :mapMarkers="mapMarkers"
                      :zoom="15"
                    ></map-picker>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <FormSubmitter
                    :hasPendingChanges="hasPendingChanges"
                    v-on:changesCanceled="changesCanceled"
                  ></FormSubmitter>
                </v-layout>
              </form>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
import TextField from "@jsroot/apps/booster-pages/editor-parts/forms/TextField"
import FormSubmitter from "@jsroot/apps/booster-pages/editor-parts/forms/FormSubmitter"
import MapPicker from '@jsroot/shared/geo/MapPicker'
import FieldResolver from "@jsroot/apps/booster-pages/editor-parts/forms/FieldResolver"
import { dirtyChecker } from "@jsroot/mixins/dirty-checker"

export default {
  mixins: [dirtyChecker],
  components: {
    FormSubmitter,
    TextField,
    FieldResolver,
    MapPicker
  },
  metaInfo() {
    return {
      title: this.currentPropForEditing.title
    }
  },
  props: {
    currentPropForEditing: {
      type: Object,
      default: () => {}
    },
    currentPropEditorFields: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    // $route(to, from) {
    //   // this.$store.dispatch('loadPropForEditing', to.params)
    // }
  },
  mounted: function() {
    // this.$store.dispatch('loadPropForEditing', this.$route.params)
  },
  computed: {
    mapMarkers: function() {
      // let address = this.$store.state.listingsEditStore.currentPropForEditing
      // let default_values = this.$store.state.listingsEditStore
      //   .propertyEditorSetup.default_values
      let default_values = {
        latitude: 36.7213028,
        longitude: -4.4216366
      }
      // lat 36.7213028 and lng -4.4216366 is malaga
      // let center = { lat: 36.72, lng: -4.42 }
      if (this.currentPropForEditing && this.currentPropForEditing.id) {
        let lat =
          this.currentPropForEditing.latitude || default_values.latitude
        let lng =
          this.currentPropForEditing.longitude || default_values.longitude
        let markerTitle = this.currentPropForEditing.street_name
        let mapMarker = {
          id: this.currentPropForEditing.id,
          title: markerTitle,
          // image_url: this.currentPropForEditing.primary_image_url,
          position: {
            lat: lat,
            lng: lng
          }
        }
        return [mapMarker]
      } else {
        return []
      }
    }
  },
  methods: {
    updatePropAddress(googleAddress) {
      let newAddressFromMap = {}
      newAddressFromMap.street_address = googleAddress.formatted_address
      this.currentPropForEditing.google_place_id = googleAddress.place_id
      this.currentPropForEditing.latitude = googleAddress.geometry.location.lat()
      this.currentPropForEditing.longitude = googleAddress.geometry.location.lng()
      // TODO - move below to Map component
      _.each(googleAddress.address_components, function(address_component, i) {
        // iterate through address_component array
        console.log("address_component:" + i)
        console.log(newAddressFromMap)
        if (address_component.types[0] === "route") {
          // console.log(i + ": route:" + address_component.long_name)
          newAddressFromMap.street_name = address_component.long_name
        }
        if (address_component.types[0] === "locality") {
          // console.log("town:" + address_component.long_name)
          newAddressFromMap.city = address_component.long_name
        }
        if (address_component.types[0] === "country") {
          // console.log("country:" + address_component.long_name)
          newAddressFromMap.country = address_component.long_name
        }
        if (address_component.types[0] === "postal_code_prefix") {
          // console.log("pc:" + address_component.long_name)
          // newAddress.postalCode = address_component.long_name
        }
        if (address_component.types[0] === "postal_code") {
          // console.log("pc:" + address_component.long_name)
          newAddressFromMap.postal_code = address_component.long_name
        }
        if (address_component.types[0] === "street_number") {
          // console.log("street_number:" + address_component.long_name)
          newAddressFromMap.street_number = address_component.long_name
        }
        if (address_component.types[0] === "administrative_area_level_1") {
          // eg: andalucia
          console.log(
            "administrative_area_level_1:" + address_component.long_name
          )
          // newAddress.province = address_component.long_name
          newAddressFromMap.region = address_component.long_name
        }
        if (address_component.types[0] === "administrative_area_level_2") {
          console.log(
            "administrative_area_level_1:" + address_component.long_name
          )
          // newAddress.aal2 = address_component.long_name
        }
        if (address_component.types[0] === "administrative_area_level_3") {
          console.log(
            "administrative_area_level_1:" + address_component.long_name
          )
          // newAddress.aal3 = address_component.long_name
        }
        if (address_component.types[0] === "administrative_area_level_4") {
          console.log(
            "administrative_area_level_1:" + address_component.long_name
          )
          // newAddress.aal4 = address_component.long_name
        }
        //return false // break the loop
      })
      this.currentPropEditorFields.mainInputFields1.forEach(function(fieldDetails) {
        // console.log(newAddressFromMap)
        fieldDetails.newValFromMap = newAddressFromMap[fieldDetails.fieldName]
      })
      this.currentPropEditorFields.mainInputFields2.forEach(function(fieldDetails) {
        fieldDetails.newValFromMap = newAddressFromMap[fieldDetails.fieldName]
      })

      // below 3 are not available via UI:
      // this.$store.dispatch('updatePendingChangeFromMap', {
      //   fieldName: "google_place_id",
      //   newValue: newAddressFromMap.place_id
      // })
      // this.$store.dispatch('updatePendingChangeFromMap', {
      //   fieldName: "latitude",
      //   newValue: newAddressFromMap.latitude
      // })
      // this.$store.dispatch('updatePendingChangeFromMap', {
      //   fieldName: "longitude",
      //   newValue: newAddressFromMap.longitude
      // })
    }
  },
  data() {
    return {
      // hasPendingChanges: false,
      // dirtyFields: {},
      // moved above 2 to dirtyChecker mixin
      fieldOptions: {},
      cancelPendingChanges: null
    }
  }
}
</script>
<style>
</style>
