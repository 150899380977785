<template>
  <v-app>
    <v-container pa-5>
      <v-layout row>
        <v-flex py-0 xs0 md2 lg3></v-flex>
        <v-flex py-0 xs12 md8 lg6>
          <div>
            <form class="container ma-2" @submit.prevent="triggerPageUpdate">
              <!-- <v-layout row>
                <FormSubmitter
                  :hasPendingChanges="hasPendingChanges"
                  v-on:changesCanceled="changesCanceled"
                ></FormSubmitter>
              </v-layout>-->
              <v-layout wrap row>
                <v-flex xs12>
                  <template v-for="(fieldDetails, index) in editorFields.mainInputFields1">
                    <FieldResolver
                      :key="index"
                      v-on:updatePendingChanges="updatePendingChanges(...arguments)"
                      :fieldDetails="fieldDetails"
                      :allSelectOptions="allSelectOptions"
                      v-bind:resourceModel="boosterPage"
                    ></FieldResolver>
                  </template>
                </v-flex>
              </v-layout>
              <v-layout wrap row>
                <v-flex class="mb-3" xs12>
                  Url:
                  <a :href="boostedPageHost">{{ boostedPageHost }}</a>
                  <a href></a>
                </v-flex>
              </v-layout>
              <v-layout row>
                <FormSubmitter
                  :hasPendingChanges="hasPendingChanges"
                  v-on:changesCanceled="changesCanceled"
                ></FormSubmitter>
              </v-layout>
            </form>
          </div>
        </v-flex>
        <v-flex py-0 xs0 md2 lg3></v-flex>
        <v-flex py-0 xs0 md2 lg3></v-flex>
        <v-flex py-0 xs12 md8 lg6>
          <v-card class>
            <v-card-title primary-title>
              <div
                v-if="saleListingWithAssets.length > 0"
                class="text-center title"
                style="width:100%"
              >Listings</div>
              <div
                v-else
                class="text-center title"
                style="width:100%"
              >There are no listings yet for this page</div>
            </v-card-title>
            <v-card-text>
              <v-list two-line header>
                <div class="text-xs-center title"></div>
                <v-list-item v-for="(listing, index) in saleListingWithAssets" :key="index">
                  <!-- <v-list-item-avatar>
                    <v-icon :class="[listing.iconClass]" v-text="listing.icon"></v-icon>
                  </v-list-item-avatar>-->

                  <v-list-item-content>
                    <v-list-item-title v-text="listing.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="listing.reference"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :href="listing.edit_url">
                    <v-btn :href="listing.edit_url" icon>
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action @click="startListingRemoval(listing)">
                    <v-btn icon>
                      <v-icon color="grey lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-row justify="center" class="pa-5 ma-5">
                <ConfirmationModal
                  :confirmationText="confirmationText"
                  @confirmedAction="runPageDelete"
                  v-bind:showModalProp.sync="promptPageDelete"
                ></ConfirmationModal>
                <ConfirmationModal
                  :confirmationText="confirmationText"
                  @confirmedAction="runListingRemoval"
                  v-bind:showModalProp.sync="promptListingRemoval"
                ></ConfirmationModal>
                <AddListingModalForm
                  :formSuccess="formSuccess"
                  :formSending="formSending"
                  :formErrors="formErrors"
                  ref="addListingModal"
                  @cleanupAddListing="cleanupAddListing"
                  @runAddListing="runAddListing"
                ></AddListingModalForm>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- <v-row justify="center">
          </v-row>-->
        </v-flex>
        <v-flex py-0 xs0 md2 lg3></v-flex>
        <v-flex py-0 xs0 md2 lg3></v-flex>
        <v-flex class="text-right" pt-3 mt-5 xs12 md8 lg6>
          <v-spacer></v-spacer>
          <v-btn dark color="red" text @click="startPageDelete">Delete this page</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import apiService from "@jsroot/services/api-public"
import ConfirmationModal from "@jsroot/apps/booster-admin/modals/confirmation-modal"
import AddListingModalForm from "@jsroot/apps/booster-admin/modal-forms/add-listing"
import FormSubmitter from "@jsroot/apps/booster-pages/editor-parts/forms/FormSubmitter"
import FieldResolver from "@jsroot/apps/booster-pages/editor-parts/forms/FieldResolver"
import { dirtyChecker } from "@jsroot/mixins/dirty-checker"
export default {
  mixins: [dirtyChecker],
  components: {
    FormSubmitter,
    ConfirmationModal,
    FieldResolver,
    AddListingModalForm
  },
  methods: {
    cleanupAddListing() {
      this.formSuccess = ""
    },
    startListingRemoval(listing) {
      this.listingToRemove = listing
      this.confirmationText = `Are you sure you want to remove ${listing.reference}?`
      this.promptListingRemoval = true
    },
    startPageDelete() {
      this.confirmationText = "Are you sure you want to delete this page?"
      this.promptPageDelete = true
    },
    runListingRemoval() {
      let apiUrl = `/api_admin/v1/booster_pages/${this.boosterPage.uuid}/remove_sale_listing/${this.listingToRemove.id}`
      apiService()
        .put(apiUrl)
        .then(
          response => {
            window.location.reload()
          },
          err => {}
        )
    },
    runPageDelete() {
      let apiUrl = "/api_admin/v1/booster_pages/" + this.boosterPage.uuid
      apiService()
        .delete(apiUrl)
        .then(
          response => {
            window.location.href = response.data.redir_path
          },
          err => {
            // if (err.response.data.error_messages) {
            //   this.formErrors = [err.response.data.error_messages]
            // } else {
            //   this.formErrors = [err]
            // }
          }
        )
    },
    runAddListing(listingRefs) {
      this.formSending = true
      let bpUuid = this.boosterPage.uuid
      let apiUrl =
        "/api_admin/v1/booster_pages/add_listing/" + bpUuid + "/" + listingRefs
      apiService()
        .put(apiUrl)
        .then(
          response => {
            this.formSending = false
            this.formSuccess = "Listing added successfully"
            // this.$refs.addListingModal.closeModal()
            window.location.href = response.data.edit_path
          },
          err => {
            this.formSending = false
            if (err.response.data.error_messages) {
              this.formErrors = [err.response.data.error_messages]
            } else {
              this.formErrors = [err]
            }
            console.log(err)
          }
        )
    },
    triggerPageUpdate() {
      let apiUrl = `/api_admin/v1/booster_pages/${this.boosterPage.uuid}`
      apiService()
        .put(apiUrl, { booster_page: this.boosterPage })
        .then(
          response => {
            // this.$refs.addListingModal.closeModal()
            location.reload()
          },
          err => {
            console.log(err)
          }
        )
    }
  },
  computed: {
    boostedPageHost() {
      let originalBoostedPageHost = INMOAPP.bsd.boosted_page_host
      // This is needed so that if the slug is changed via the dropdown I can update
      // the target url
      if (
        this.boosterPage.slug &&
        originalBoostedPageHost.includes(this.boosterPage.slug)
      ) {
        return originalBoostedPageHost
      } else {
        return originalBoostedPageHost.replace(
          INMOAPP.bsd.boosted_page_slug,
          this.boosterPage.slug
        )
      }
    }
  },
  mounted() {
    apiService().refreshCsrf()
    if (INMOAPP.bsd && INMOAPP.bsd.booster_page) {
      this.boosterPage = INMOAPP.bsd.booster_page
    }
    this.editorFields = INMOAPP.bsd.fields
    this.saleListingWithAssets = INMOAPP.bsd.sale_listing_with_assets
    this.allSelectOptions = INMOAPP.bsd.select_options
    // this.allSelectOptions = {
    //   slug: ["baa"]
    // }
  },
  created() {},
  // beforeDestroy() {
  //   this.$eventHub.$off("more-info")
  // },
  data: function() {
    return {
      listingToRemove: null,
      confirmationText: "",
      promptListingRemoval: false,
      promptPageDelete: false,
      formErrors: [],
      formSending: false,
      formSuccess: "",
      editorFields: {},
      boosterPage: {},
      allSelectOptions: {},
      saleListingWithAssets: []
      // cancelPendingChanges: null,
      // showAddListingModal: false
    }
  }
}
</script>
<style scoped>
</style>
