<template>
  <v-dialog max-width="190" v-model="showModal">
    <v-card justify="center" class="inmo-card px-0 py-0">
      <v-card-text>
        <div class="py-4 title">{{confirmationText}}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn dark color="red" text @click="actionConfirmed">Yes</v-btn>
        <v-btn dark color="green" text @click="actionCancelled">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
</style>
<script>
export default {
  components: {},
  props: {
    confirmationText: {
      type: String
    },
    showModalProp: {
      type: Boolean
    }
  },
  // mounted: function() {  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    showModal: {
      // getter
      get: function() {
        return this.showModalProp
      },
      // setter
      set: function(newValue) {
        this.$emit("update:showModalProp", newValue)
      }
    }
  },
  methods: {
    actionConfirmed() {
      this.$emit("update:showModalProp", false)
      this.$emit("confirmedAction")
    },
    actionCancelled() {
      this.$emit("update:showModalProp", false)
    }
  }
}
</script>
<style>
</style>