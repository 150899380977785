// Will show an input per locale for
// each single field - locales specified in FieldResolver
<template>
  <div>
    <template v-for="localField in localFieldDetails">
      <v-textarea
        :key="localField.fieldName"
        v-if="fieldDetails.rows"
        :rows="fieldDetails.rows"
        :name="localField.fieldName"
        :type="type"
        :label="localField.label"
        v-on:keyup="fieldChangeHandler(resourceModel[localField.fieldName], localField)"
        v-model="resourceModel[localField.fieldName]"
      ></v-textarea>
      <v-text-field
        v-else
        :key="localField.fieldName"
        :name="localField.fieldName"
        :type="type"
        :label="localField.label"
        v-on:keyup="fieldChangeHandler(resourceModel[localField.fieldName], localField)"
        v-model="resourceModel[localField.fieldName]"
      ></v-text-field>
    </template>
  </div>
</template>
<script>
export default {
  props: [
    "fieldDetails",
    "resourceModel",
    "cancelPendingChanges",
    "shortLocales"
  ],
  data() {
    return {
      localFieldDetails: []
      // localFieldValue: ""
      // originalValue: ""
    }
  },
  watch: {
    // cancelPendingChanges(newValue, oldValue) {
    //   if (oldValue === false) {
    //     // when cancelPendingChanges on parent changes from
    //     // false to true
    //     // reset model to its original value
    //     this.localFieldValue = this.originalValue
    //   }
    // },
    // "fieldDetails.newValFromMap"(newValue, oldValue) {
    //   if (newValue && newValue.length > 0) {
    //     // This triggers when map marker is dragged and dropped
    //     this.localFieldValue = newValue
    //     this.fieldDetails.newValue = newValue
    //     this.$emit("updatePendingChanges", this.fieldDetails)
    //   }
    // },
  },
  created() {
    let localFieldDetails = []
    let originalValue = {}
    // values of above and below will be the same here but need diff
    // objects so they updated independently (might have been able to use _.clone())
    let newValue = {}
    this.shortLocales.forEach(shortLocale => {
      let fieldName = this.fieldDetails.fieldName + shortLocale
      let label = this.fieldDetails.label
      if (this.shortLocales.length > 1) {
        // Only show what locale this field is for
        // if there are multiple locales
        label = label + " (" + shortLocale + ")"
      }
      let fd = {
        shortLocale: shortLocale,
        label: label,
        fieldName: fieldName
      }
      localFieldDetails.push(fd)
      originalValue[shortLocale] = this.resourceModel[fieldName]
      newValue[shortLocale] = this.resourceModel[fieldName]
    })
    // Have to jump through all these hoops so I can support editing multiple
    // locals
    this.fieldDetails.originalValue = originalValue
    this.fieldDetails.newValue = newValue
    this.localFieldDetails = localFieldDetails
    // this.fieldDetails.label = this.fieldDetails.label + this.shortLocale
    // let tpn = this.fieldDetails.fieldName + "en"
    // this.localFieldValue = this.resourceModel[tpn]
    // this.fieldDetails.originalValue = this.localFieldValue || ""
  },
  computed: {
    // localFieldDetails() {
    //   let localFieldDetails = []
    //   let originalValue = {}
    //   this.shortLocales.forEach(shortLocale => {
    //     let fieldName = this.fieldDetails.fieldName + shortLocale
    //     let label = this.fieldDetails.label + " (" + shortLocale + ")"
    //     let fd = {
    //       label: label,
    //       fieldName: fieldName
    //     }
    //     localFieldDetails.push(fd)
    //     originalValue[shortLocale] = this.resourceModel[fieldName]
    //   })
    //   // In theory probably not a good ides:
    //   this.fieldDetails.originalValue = originalValue
    //   this.fieldDetails.newValue = originalValue
    //   return localFieldDetails
    // },
    type() {
      if (this.fieldDetails.fieldDbType === "int") {
        return "number"
      }
    }
  },
  methods: {
    fieldChangeHandler(newValue, field) {
      // let newValue = event.currentTarget.value
      this.fieldDetails.newValue[field.shortLocale] = newValue
      this.$emit("updatePendingChanges", this.fieldDetails)
    }
  }
}
</script>