<template>
  <div>
    <div class="text-xs-left">{{fieldDetails.label }}:</div>
    <v-select
      :items="selectOptions"
      v-model="localFieldValue"
      label="Select"
      @change="fieldChangeHandler"
      item-text="name"
      single-line
      bottom
    ></v-select>
  </div>
</template>
<script>
export default {
  props: [
    "fieldDetails",
    "currentFieldValue",
    "cancelPendingChanges",
    "selectOptions"
  ],
  data() {
    return {
      localFieldValue: "",
      originalValue: ""
    }
  },
  watch: {
    cancelPendingChanges(newValue, oldValue) {
      if (oldValue === false) {
        // when cancelPendingChanges on parent changes from
        // false to true
        // reset model to its original value
        this.localFieldValue = this.originalValue
      }
    },
    currentFieldValue: {
      handler(newValue, oldVal) {
        if (newValue) {
          this.localFieldValue = newValue.toString()
          this.originalValue = newValue.toString()
        }
      },
      // deep: true,
      immediate: true
    }
  },
  computed: {
    // selectItems() {
    //   let untranslated = this.fieldOptions[this.fieldDetails.optionsKey] || []
    //   if (this.fieldDetails.optionsValues) {
    //     untranslated = this.fieldDetails.optionsValues
    //   }
    //   let selectItems = [{ name: "", value: "" }]
    //   let i18n = this.$i18n
    //   let fieldName = this.fieldDetails.fieldName
    //   if (untranslated) {
    //     untranslated.forEach(function(optionKey) {
    //       if (optionKey.name) {
    //         // May 2019 - Added this
    //         // so I can use project progress values
    //         // which are key:value pairs
    //         selectItems.push({
    //           name: optionKey.name,
    //           value: optionKey.value
    //         })
    //       } else {
    //         let name = i18n.t(optionKey)
    //         if (fieldName === "currency") {
    //           optionKey = optionKey.toUpperCase()
    //         }
    //         selectItems.push({
    //           name: name,
    //           value: optionKey
    //         })
    //       }
    //     })
    //   }
    //   return selectItems
    // },
  },
  methods: {
    fieldChangeHandler(newValue) {
      // let newValue = event.currentTarget.value
      this.fieldDetails.newValue = newValue
      // this.$store.dispatch('updatePendingChanges', this.fieldDetails)

      this.$emit("updatePendingChanges", this.fieldDetails)
    }
  }
}
</script>