// sites-2018/pwb-malaga/app/javascript/shared-admin/components-edit/ImgUploaderUppy.vue
// https://gist.github.com/pooot/a2f86e6847a8be51fb2e3672a00a905f
// Based on above
<template>
  <div>
    <v-layout wrap row>
      <v-flex xs4 sm4 md2>
        <v-btn color class="accent">
          <div class="UppyForm">
            <form action="//api2.transloadit.com">
              <h5>Uppy was not loaded — slow connection, unsupported browser, weird JS error on a page — but the upload still works, because HTML is cool like that</h5>
              <input type="file" name="files[]" multiple />
              <button type="submit">Fallback Form Upload</button>
            </form>
          </div>
        </v-btn>
      </v-flex>
      <v-flex xs8 sm8 md6 pa-3>
        <div class="UppyInput-Status"></div>
      </v-flex>
    </v-layout>
    <div style="display: none;" :id="uppyId">
      <div class="ThumbnailContainer" v-if="collection === 'thumbnail'">
        <button id="open-thumbnail-modal" class="button">Select file</button>
      </div>
      <div class="DashboardContainer" v-else></div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
// import VueCoreImageUpload from 'vue-core-image-upload'

const Uppy = require("@uppy/core")
const Dashboard = require("@uppy/dashboard")
const XHRUpload = require("@uppy/xhr-upload")
const FileInput = require("@uppy/file-input")
// const ProgressBar = require('@uppy/progress-bar')
const StatusBar = require("@uppy/status-bar")

export default {
  data() {
    return {
      uppy: null,
      uploadData: {
        // test: "ddd"
      },
      upLoadingDialog: false,
      uploadSuccess: false,
      selectedFile: null
    }
  },
  components: {
    // VueCoreImageUpload
  },
  props: {
    modelClass: {
      type: String,
      required: true
    },
    modelId: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    imageUploadUrl: {
      type: String,
      required: false,
      default() {
        return "/upload"
      }
    }
  },
  // props: ["imageUploadUrl", "allowAltEditing", "text", "uploaderBtnClass"],
  computed: {
    uppyId() {
      return this.modelClass + "-" + this.modelId + "-" + this.collection
    }
  },

  mounted() {
    this.uppy = Uppy({
      id: this.uppyId,
      autoProceed: true,
      debug: true
      // restrictions: {
      //   maxFileSize: false,
      //   allowedFileTypes: ['image/*', 'application/pdf']
      // },
      // meta: {
      //   modelClass: this.modelClass,
      //   modelId: this.modelId,
      //   collection: this.collection
      // },
      // onBeforeFileAdded: (currentFile, files) => Promise.resolve(),
      // onBeforeUpload: (files, done) => Promise.resolve(),
    })
    // uppy.use(Dashboard, {
    //   trigger: '.UppyModalOpenerBtn',
    //   inline: true,
    //   target: '.DashboardContainer',
    //   replaceTargetContent: true,
    //   showProgressDetails: true,
    //   note: 'Images and video only, 2–3 files, up to 1 MB',
    //   height: 470,
    //   metaFields: [
    //     { id: 'name', name: 'Name', placeholder: 'file name' },
    //     { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
    //   ],
    //   browserBackButtonClose: true
    // })
    this.uppy.use(FileInput, {
      target: ".UppyForm",
      replaceTargetContent: true
    })
    // this.uppy.use(ProgressBar, {
    //   target: 'body',
    //   fixed: true,
    //   hideAfterFinish: false
    // })
    this.uppy.use(StatusBar, {
      target: ".UppyInput-Status",
      hideUploadButton: false,
      hideAfterFinish: false,
      showProgressDetails: true
    })
    // this.uppy.on('complete', (result) => {
    //   console.log('Upload complete! We’ve uploaded these files:', result.successful)
    // })
    this.uppy.on("upload-success", (file, response) => {
      this.$emit("imageuploaded", response.body)
      console.log(file.name, response.uploadURL)
      // var img = new Image()
      // img.width = 300
      // img.alt = file.id
      // img.src = response.uploadURL
      // document.body.appendChild(img)
    })
    // this.uppy.on('file-added', (file) => {
    //   // Do something
    // })

    // this.uppy.on('upload', (data) => {
    //   // Do something
    // })
    let xCsrfToken = INMOAPP.xCsrfToken
    this.uppy.use(XHRUpload, {
      endpoint: this.imageUploadUrl,
      formData: true,
      fieldName: "files[]",
      headers: {
        "X-CSRF-TOKEN": xCsrfToken
      }
    })
    this.uppy.run()
  },
  // If imageUploadUrl will not be ready on mount
  // then I'll have to activate watcher below
  // watch: {
  //   imageUploadUrl: {
  //     handler(newVal, oldVal) {
  //       let xCsrfToken = INMOAPP.xCsrfToken
  //       this.uppy.use(XHRUpload, {
  //         endpoint: newVal,
  //         formData: true,
  //         fieldName: "files[]",
  //         headers: {
  //           "X-CSRF-TOKEN": xCsrfToken
  //         }
  //       })
  //     },
  //     // deep: true,
  //     immediate: false
  //   }
  // }
}
</script>
<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/status-bar/dist/style.css"></style>