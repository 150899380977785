<template>
  <section>
    <!-- <CreatePromotionModal @hideCreatePRModal="hideCreatePRModal" :createPRModalVisible="createPRModalVisible"></CreatePromotionModal> -->
    <v-container pa-0>
      <v-toolbar color="cyan" style="display:none;" flat>
        <v-toolbar-title>
          <a
            class="btn my-flat btn--router accent--text"
            style="position: relative;"
            :href="{path: viewPropertyHref}"
            flat
          >
            <v-btn color class="accent">
              {{ $ft('client_admin.editor.viewProperty')}}
              <v-icon class="pr-2" medium></v-icon>
            </v-btn>
          </a>
        </v-toolbar-title>
        <v-toolbar-title style="min-width:200px;">
          <div>
            <v-switch
              dark
              class="mt-4"
              row
              :prepend-icon="publishedIcon"
              v-bind:label="publishedLabel"
              v-model="publishedValue"
            ></v-switch>
          </div>
        </v-toolbar-title>
        <!-- <v-toolbar-title style="min-width:250px;">
        </v-toolbar-title>-->
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-btn color class="accent" @click.native="showCreatListingsModal()">
            <v-icon class="pr-2" medium>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs v-model="selectedTab" dark background-color="cyan">
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab
          v-for="(tab) in propertyEditorSetup.tabs"
          :key="tab.routeComp"
          class="cyan"
        >{{ tab.label }}</v-tab>
      </v-tabs>
      <!-- <router-view /> -->
      <component
        :is="activeComp"
        @updateListing="updateListing"
        :currentPropForEditing="currentPropForEditing"
        :currentPropEditorFields="activeEditorFields"
      ></component>
      <!-- <ConfirmationModal
        @confirmedAction="runListingDelete"
        v-bind:showModalProp.sync="showConfirmationModal"
      ></ConfirmationModal> -->
      <v-flex class="text-right" pt-3 xs12>
        <v-spacer></v-spacer>
        <!-- <v-btn dark color="red" text @click="startListingDelete">Delete this listing</v-btn> -->
      </v-flex>
    </v-container>
  </section>
</template>
<script>
import apiService from "@jsroot/services/api-public"
import PropertyEditText from "@jsroot/apps/booster-pages/editor-parts/PropertyEditText"
import PropertyEditSeo from "@jsroot/apps/booster-pages/editor-parts/PropertyEditSeo"
import PropertyEditLocation from "@jsroot/apps/booster-pages/editor-parts/PropertyEditLocation"
// // import PropertyEditFeatures from "@jsroot/apps/booster-pages/editor-parts/PropertyEditFeatures"
import PropertyEditPhotos from "@jsroot/apps/booster-pages/editor-parts/PropertyEditPhotos"
import PropertyEditAttributes from "@jsroot/apps/booster-pages/editor-parts/PropertyEditAttributes"
import ConfirmationModal from "@jsroot/apps/booster-admin/modals/confirmation-modal"
export default {
  components: {
    ConfirmationModal,
    PropertyEditLocation,
    PropertyEditPhotos,
    PropertyEditSeo,
    PropertyEditText,
    PropertyEditAttributes
  },
  props: [],
  methods: {
    startListingDelete() {
      this.showConfirmationModal = true
    },
    // runListingDelete() {
    //   let apiUrl = "/api_admin/v1/sale_listings/" + this.currentPropForEditing.id
    //   apiService()
    //     .delete(apiUrl)
    //     .then(
    //       response => {
    //         let redir_url = this.currentPropForEditing.page_edit_url || "/"
    //         window.location.href = redir_url
    //         //  response.data.redir_path
    //       },
    //       err => {
    //       }
    //     )
    // },
    updateListing() {
      let apiUrl = `/api_admin/v1/sale_listings/update_asset/${this.currentPropForEditing.id}`
      apiService()
        .put(apiUrl, { listing: this.currentPropForEditing })
        .then(
          response => {
            location.reload()
            // TODO - display success or error
          },
          err => {
            console.log(err)
          }
        )
    },
    showCreatListingsModal() {
      this.createPRModalVisible = true
    }
  },
  watch: {
    selectedTab: {
      handler(newVal, oldVal) {
        let activeTab = this.propertyEditorSetup.tabs[newVal]
        this.activeComp = activeTab.routeComp
        this.activeEditorFields = this.propertyEditorSetup.fields[
          this.activeComp
        ]
        // location.hash = activeTab.label
      },
      // deep: true,
      immediate: false
    }
  },
  mounted() {
    apiService().refreshCsrf()
    if (INMOAPP.bsd && INMOAPP.bsd.models) {
      this.currentPropForEditing = INMOAPP.bsd.models.listing
    }
    if (INMOAPP.bsd && INMOAPP.bsd.editor_setup) {
      this.propertyEditorSetup = INMOAPP.bsd.editor_setup
    }
    this.activeEditorFields = this.propertyEditorSetup.fields[this.activeComp]
    // Apr 2020 - considered using location.hash to figure out which
    // component to load but not enough bang for buck with it
  },
  computed: {
    // activeComp() {
    //   if (window.location.hash.toLowerCase() === "editpropphotos") {
    //     return "PropertyEditText"
    //   } else {
    //     return "PropertyEditAttributes"
    //   }
    // },
    showOnHomepageValue: {
      get: function() {
        return this.currentPropForEditing.highlighted || false
      },
      set: function(newValue) {
        this.currentPropForEditing.highlighted = newValue
        // when below is called, any pending changes will also be applied
        this.$store.dispatch("updateProperty")
        // need to decide if this is a good thing or not
      }
    },
    showOnHomepageIcon() {
      if (this.showOnHomepageValue) {
        return "home"
      } else {
        return ""
      }
    },
    showOnHomepageLabel() {
      if (this.showOnHomepageValue) {
        return "Show on home page"
      } else {
        return "Hide on home page"
      }
    },
    publishedValue: {
      get: function() {
        return this.currentPropForEditing.visible || false
      },
      set: function(newValue) {
        this.currentPropForEditing.visible = newValue
        this.$store.dispatch("updateProperty")
      }
    },
    // publishedValue() {
    //   return this.currentPropForEditing.visible || false
    // },
    publishedIcon() {
      if (this.publishedValue) {
        return "visibility"
      } else {
        return "visibility_off"
      }
    },
    publishedLabel() {
      if (this.publishedValue) {
        return "Published"
      } else {
        return "Not Published"
      }
    }
    // currentPropForEditing() {
    //   return this.currentPropForEditing
    // }
  },
  data() {
    return {
      showConfirmationModal: false,
      activeComp: null,
      //  "PropertyEditSeo",
      selectedTab: null,
      propertyEditorSetup: {},
      activeEditorFields: {},
      currentPropForEditing: {},
      createPRModalVisible: false,
      viewPropertyHref: ""
      // urlsToBeRetrieved: true,
      // propertyTabs: [],
    }
  }
}
</script>
<style scoped>
</style>