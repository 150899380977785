<template>
  <div>
    <div>
      {{ $ft('client_admin.tips.dragMarker') }}
    </div>
    <gmap-map ref="gmapRef" style="min-height: 600px;" :zoom="15" :center="center">
<!--       <gmap-info-window ref="infwin" :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      </gmap-info-window> -->
      <gmap-marker :position="draggableMarker.position" @mouseout="statusText = null" :clickable="true" :draggable="true" @dragend="updateCoordinates" @click=""></gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
export default {
  props: ["mapMarkers", "noInfoWindow"],
  data: function() {
    return {
      statusText: '',
      // center is a required prop
      // center: { lat: 10, lng: 10 },
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      // TODO: - allow setting of defaults for above
      // from server
      // infoWinOpen: false,
      currentMindex: null,
      infoOptions: {
        content: "hey",
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      newPosSelectedTitle: null
    }
  },
  computed: {
    draggableMarker() {
      if (this.mapMarkers[0] && this.mapMarkers[0].position.lat) {
        return this.mapMarkers[0]
      } else {
        let center = { lat: 36.7, lng: -4.4 }
        if (this.fallbackCenter && this.fallbackCenter.lat) {
          center = {
            lng: this.fallbackCenter.lng,
            lat: this.fallbackCenter.lat
          }
        }
        let mapMarker = {
          position: {
            lat: center.lat,
            lng: center.lng
          }
        }
        return mapMarker
      }
    },
    center() {
      // lat 36.7213028 and lng -4.4216366 is malaga
      let center = { lat: 36.72, lng: -4.42 }
      if (this.mapMarkers[0] && this.mapMarkers[0].position.lat) {
        let lat = this.mapMarkers[0].position.lat
        let lng = this.mapMarkers[0].position.lng
        center = { lat: lat, lng: lng }
      } else {
        if (this.fallbackCenter && this.fallbackCenter.lat) {
          center = {
            lng: this.fallbackCenter.lng,
            lat: this.fallbackCenter.lat
          }
        }
      }
      return center
    }
  },
  methods: {
    updateCoordinates(location) {
      this.infoWinOpen = false
      var geocoder = new google.maps.Geocoder()
      var that = this
      geocoder.geocode({
        'latLng': location.latLng
      }, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            // that.mapMarkers[0].title = results[0].formatted_address
            // when I change the title above directly it does not work
            // so using below
            that.newPosSelectedTitle = results[0].formatted_address
            that.$emit('updatePropAddress', results[0])
          } else {
            // alert("No results found");
          }
        } else {
          // alert("Geocoder failed due to: " + status);
        }
      });

    },
    resizeMap: function() {
      // marker display won't work without this method
      var that = this
      this.$refs.gmapRef.$mapPromise.then(function() {
        if (that.mapMarkers.length === 1) {
          let lat = that.mapMarkers[0].position.lat
          let lng = that.mapMarkers[0].position.lng
          let center = { lat: lat, lng: lng }
          // that.center = center
          that.$refs.gmapRef.$mapObject.setCenter(center)
          that.$refs.gmapRef.$mapObject.setZoom(17)
          // if (map.getZoom() > 16) map.setZoom(16); 
        }
        if (that.mapMarkers.length > 1) {
          // let google = {}
          var bounds = new google.maps.LatLngBounds()
          that.mapMarkers.forEach(function(mapker) {
            bounds.extend(mapker.position)
          })
          // for (let m of that.mapMarkers) {
          //   bounds.extend(m.position)
          // }

          // that.$refs.gmapRef.$mapObject.fitBounds(bounds)
          // could also call fitBounds on raw mapObject like above
          that.$refs.gmapRef.fitBounds(bounds)
          // where markers are too close together, I need below
          // to ensure they are not too zoomed in
          // that.$refs.gmapRef.$mapObject.setOptions({ maxZoom: that.$refs.gmapRef.$mapObject.getZoom() })
        }
      })
    },
    // toggleInfoWindow: function(marker, index) {
    //   if (this.noInfoWindow) {
    //     return false
    //   }
    //   this.infoWindowPos = marker.position
    //   var markerTitle = this.newPosSelectedTitle || marker.title
    //   var infoWindowContent = '<div class="iw-container">' +
    //     '<a href="' + marker.show_url + '" class="iw-href">' +
    //     '<div class="iw-content">' +
    //     // image_html +
    //     '</div>' +
    //     '<div class="iw-title primary--text">' + markerTitle + '</div>' +
    //     '</a>' +
    //     '</div>'

    //   this.infoOptions.content = infoWindowContent
    //   // check if its the same marker that was selected if yes toggle
    //   if (this.currentMindex === index) {
    //     this.infoWinOpen = !this.infoWinOpen
    //   } else {
    //     // if different marker set infowindow to open and reset current marker index
    //     this.infoWinOpen = true
    //     this.currentMindex = index
    //   }
    // }
  },
  watch: {
    mapMarkers(mapMarkers) {
      this.resizeMap()
    }
  }
}
</script>
<style>
.iw-container {
  max-width: 220px;
  overflow: hidden;
  margin-top: 6%;
}

.iw-href {
  text-decoration: none;
}

.iw-title {
  font-size: 16px;
  font: 400 20px Raleway, sans-serif;
}
</style>