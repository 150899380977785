/* eslint no-console: 0 */
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import Vue from 'vue/dist/vue.esm'
import Vue from 'vue'

import '@jsroot/css/bvh.css'
import '@jsroot/css/listing-details.css'
import 'vuetify/src/styles/styles.sass'
// It is only when I activate vuetify below that
// stylesheets get injected
// import vuetify from '../plugins/vuetify' // path to vuetify export
import BoosterPageEdit from '@jsroot/apps/booster-admin/pages/booster-page-edit.vue'
import SaleListingEdit from '@jsroot/apps/booster-admin/pages/sale-listing-edit.vue'
import BoosterPagesList from '@jsroot/apps/booster-admin/pages/pages-list.vue'

import FastI18n from '../plugins/FastI18n'
Vue.use(FastI18n)

import Vuetify from 'vuetify/lib'

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    // TODO - inject key via server side
    key: 'AIzaSyCPorm8YzIaUGhKfe5cvpgofZ_gdT8hdZw',
    // libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  }
})

Vue.use(Vuetify)
const vuetify = new Vuetify({})
// import TurbolinksAdapter from 'vue-turbolinks'
// Decided to use my own implementation of above:
import TurbolinksAdapter from '@jsroot/mixins/vue-turbolinks-mix'
Vue.use(TurbolinksAdapter)


var bus = new Vue()
// https://riptutorial.com/vue-js/example/20841/how-to-deal-with-deprecation-of--dispatch-and--broadcast---bus-event-pattern-
window.boosterBus = bus
// Instead of below could also pass in via data to each
// vue instance but this seems cleaner
Vue.prototype.$eventHub = bus // Global event bus
// https://medium.com/vuejobs/create-a-global-event-bus-in-vue-js-838a5d9ab03a
let readyForMainApp = true
// TODO - only initiate above as false for mobile apps
document.addEventListener('turbolinks:load', () => {
  // document.addEventListener('DOMContentLoaded', () => {
  if (readyForMainApp) {
    loadMainApp()
  }
  else {
    window.addEventListener('scroll', onAppScroll)
  }
})
function onAppScroll(e) {
  readyForMainApp = true
  // above so future "turbolinks:load" events go straight to loadMainApp
  loadMainApp()
  window.removeEventListener('scroll', onAppScroll)
}
function loadMainApp(params) {
  let infoEl = document.getElementById("info-el")
  let mainCompName = ""
  if (infoEl) {
    let targetElId = infoEl.getAttribute('data-target-id')
    mainCompName = infoEl.getAttribute('data-main-comp')
    let compToRender = BoosterPageEdit
    // if (["SaleListingDetails",
    //   "BoosterPagesList",
    //   "BoosterLanding"].includes(mainCompName)) {
    //   eval("compToRender = " + mainCompName)
    // }
    // Tried to constantize above but doesn't work :(
    // https://gist.github.com/technicolorenvy/4956138

    // if (mainCompName === "SaleListingDetails") {
    //   compToRender = SaleListingDetails
    // }
    if (mainCompName === "BoosterPagesList") {
      compToRender = BoosterPagesList
    }
    // if (mainCompName === "BoosterLanding") {
    //   compToRender = BoosterLanding
    // }
    if (mainCompName === "SaleListingEdit") {
      compToRender = SaleListingEdit
    }
    // Ocassionaly get error in js about not finding the targetElId element
    // if (document.getElementById("vmd-app-btm")) {
    var targetElement = document.createElement('div')
    targetElement.id = 'vue-target-div'
    // document.body.appendChild(div);
    // var component = new MyComponent().$mount()
    var targetParent = document.getElementById('vmd-app-btm')
    if (targetParent.children[0]) {
      targetParent.replaceChild(targetElement, targetParent.children[0])
    } else {
      targetParent.appendChild(targetElement)
    }

    window.appBtm = new Vue({
      // el: "#vmd-app-btm11",
      vuetify,
      // Considered passing in bus below but went for prototype instead
      // data: () => {
      //   return {
      //     bus: bus,
      //   }
      // },
      render: h => h(compToRender)
      // Trying below results in the message
      // Either pre-compile the templates into render functions, or use the compiler-included build
      // components: { BoosterPageEdit, BoosterPagesList }
    // }).$mount(targetElId)
    }).$mount('#vue-target-div')

  }
}
// document.addEventListener('DOMContentLoaded', () => {
//   const appTop = new Vue({
//     el: '#vmd-app-top',
// data: () => {
//   return {
//     // menuVisible: false
//     // message: "Can you say hello?"
//   }
// },
//     // components: { BoosterPageEdit, BoosterPagesList }
//   })
// })
// below for stats:
Vue.config.performance = true
