<template>
  <div>
    <v-textarea
      v-if="fieldDetails.rows"
      :rows="fieldDetails.rows"
      :name="fieldDetails.fieldName"
      :type="type"
      :label="fieldDetails.label"
      v-on:keyup="fieldChangeHandler"
      :autofocus="fieldDetails.autofocus"
      v-model="localFieldValue"
    ></v-textarea>
    <v-text-field
      v-else
      :name="fieldDetails.fieldName"
      :type="type"
      :label="fieldDetails.label"
      v-on:keyup="fieldChangeHandler"
      :autofocus="fieldDetails.autofocus"
      v-model="localFieldValue"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: ["fieldDetails", "currentFieldValue", "cancelPendingChanges"],
  data() {
    return {
      localFieldValue: "",
      // originalValue: ""
    }
  },
  watch: {
    // cancelPendingChanges(newValue, oldValue) {
    //   if (oldValue === false) {
    //     // when cancelPendingChanges on parent changes from
    //     // false to true
    //     // reset model to its original value
    //     this.localFieldValue = this.originalValue
    //   }
    // },
    "fieldDetails.newValFromMap"(newValue, oldValue) {
      if (newValue && newValue.length > 0) {
        // This triggers when map marker is dragged and dropped
        this.localFieldValue = newValue
        this.fieldDetails.newValue = newValue
        this.$emit("updatePendingChanges", this.fieldDetails)
      }
    },
    // currentFieldValue: {
    //   handler(newValue, oldVal) {
    //     // Apr 2020 - will use above to dirty check
    //     // This is effectively an initializer
    //     // that will not change as a result of typing
    //     // Will retrigger though when an update is pushed
    //     // to the server
    //     if (newValue) {
    //       if (this.fieldDetails.fieldType === "localesHash") {
    //         newValue = newValue[this.fieldDetails.activeLocale]
    //       }
    //       if (this.fieldDetails.inputType === "currency") {
    //         newValue = newValue / 100
    //       }
    //     }
    //     this.localFieldValue = newValue
    //     this.originalValue = newValue
    //   },
    //   // deep: true,
    //   immediate: true
    // }
  },
  created() {
    this.fieldDetails.originalValue = this.currentFieldValue || ""
    this.localFieldValue = this.currentFieldValue
  },
  computed: {
    // not going to use mask prop for textfield
    // cos it conflicts with an input type of number
    // mask() {
    //   return "#"
    // },
    type() {
      if (this.fieldDetails.fieldDbType === "int") {
        // TODO - use number for fieldDbType
        return "number"
      }
    }
  },
  methods: {
    fieldChangeHandler(event) {
      let newValue = event.currentTarget.value
      if (this.fieldDetails.inputType === "currency") {
        // apr 2020 TODO - find better way of handling currencies
        newValue = newValue * 100
      }
      // in case of (this.fieldDetails.fieldType === "localesHash")
      // the updatePendingPropChanges function in the store will take
      // care of re-structuring the data
      this.fieldDetails.newValue = newValue
      this.$emit("updatePendingChanges", this.fieldDetails)
    }
  }
}
</script>