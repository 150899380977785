<template>
  <v-flex v-if="hasPendingChanges" xs12 sm12 offset-sm0>
    <v-btn class="primary" type="submit">
      {{ $ft('client_admin.editor.save')}}
    </v-btn>
    <v-btn @click="$emit('changesCanceled')">
      {{ $ft('client_admin.editor.cancel')}}
    </v-btn>
  </v-flex>
</template>
<script>
export default {
  components: {},
  props: ["hasPendingChanges"],
}
</script>