// Taken from
// https://github.com/jeffreyguenther/vue-turbolinks/blob/master/index.js
function handleVueDestruction(vue) {
  var turbolinksEvent = vue.$options.turbolinksDestroyEvent || 'turbolinks:visit';
  document.addEventListener(turbolinksEvent, function teardown() {
    console.log("Old vue destroyed")
    vue.$destroy();
    document.removeEventListener(turbolinksEvent, teardown);
  });
}

// https://github.com/jeffreyguenther/vue-turbolinks/issues/16
// https://github.com/jeffreyguenther/vue-turbolinks/pull/8
var turbolinksAdapterMixin = {
  beforeMount: function () {
    // If this is the root component, we want to cache the original element contents to replace later
    // We don't care about sub-components, just the root
    if (this === this.$root && this.$el) {
      handleVueDestruction(this);
      // cache original element
      //   this.$turbolinksCachedHTML = this.$el.outerHTML;
      //   // register root hook to restore original element on destroy
      //   this.$once('hook:destroyed', function () {
      //     this.$el.outerHTML = this.$turbolinksCachedHTML
      //   });
      // Above is how this is implemented in the original
      // Tried below but it was too yanky:
      // if (this.$el.outerHTML) {
      //   this.$turbolinksCachedHTML = this.$el.outerHTML;
      //   // register root hook to restore original element on destroy
      //   this.$once('hook:destroyed', function () {
      //     this.$el.outerHTML = this.$turbolinksCachedHTML
      //   });
      // }
      this.$turbolinksCachedHTML = this.$el;
      // register root hook to restore original element on destroy
      this.$once('hook:destroyed', function () {
        this.$el = this.$turbolinksCachedHTML
      });
    }
  }
};

function plugin(Vue, options) {
  // Install a global mixin
  Vue.mixin(turbolinksAdapterMixin)
}

export { turbolinksAdapterMixin };
export default plugin;