<template>
  <div>
    <v-layout wrap row>
      <v-dialog v-model="upLoadingDialog" hide-overlay persistent width="300">
        <v-card color="secondary" dark>
          <v-card-text>
            <div v-if="uploadSuccess">
              {{uploadSuccess}}
            </div>
            <div v-else>
              Please stand by
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <button @click="$refs.fileInput.click()" class="uploaderBtnClass">{{ text }}</button>
    <input style="display: none;" type="file" @change="onFileChanged" ref="fileInput" multiple="multiple" />
    <!--     <vue-core-image-upload :crop="false" @imagechanged="imagechanged" @imageuploading="imageuploading" @imageuploaded="imageuploaded" :text="text" :class="uploaderBtnClass" :data="uploadData" :headers="imageUploadHeaders" :max-file-size="5242880" :url="imageUploadUrl">
    </vue-core-image-upload> -->
  </div>
</template>
<script>
import axios from 'axios'
// import VueCoreImageUpload from 'vue-core-image-upload'
export default {
  data() {
    return {
      uploadData: {
        // test: "ddd"
      },
      upLoadingDialog: false,
      uploadSuccess: false,
      selectedFile: null
    }
  },
  components: {
    // VueCoreImageUpload
  },
  props: ["imageUploadUrl", "allowAltEditing", "text", "uploaderBtnClass"],
  computed: {
    imageUploadHeaders() {
      var token = document.getElementsByName('csrf-token')[0].getAttribute('content')
      return {
        "X-CSRF-Token": token,
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  },
  methods: {
    onFileChanged(event) {
      const files = event.target.files
      const formData = new FormData()
      let imageUploadUrl = this.imageUploadUrl
      if (files.length === 1) {
        this.selectedFile = event.target.files[0]
        formData.append('file', this.selectedFile, this.selectedFile.name)
      } else {
        imageUploadUrl = imageUploadUrl + "/multiple"
        this.selectedFile = event.target.files[0]
        formData.append('files', files, "dd")
      }
      // let that = this
      debugger
      axios.post(imageUploadUrl, formData, {
        onUploadProgress: progressEvent => {
          console.log(progressEvent.loaded / progressEvent.total)
        }
      }).then(response => {
        // console.log(this)
        // console.log(that)
        this.$emit('imageuploaded', response.data)
        // this.pageForEditing.primary_image_url = res.image.url
        // this.$store.commit('setPropImages', { result: res })
        this.uploadSuccess = "Image uploaded successfully"
      }, (err) => {
        // let errMessage = err.response.data.error || err.message
        // let errResult = {
        //   errors: [errMessage]
        // }
        // commit('parseSignInError', { result: errResult })
        // console.log(err)
      })
    },
    // imageuploaded(res) {
    //   if (res.error) {
    //     this.uploadSuccess = "Sorry there has been an error"
    //     // this.src = 'http://img1.vued.vanthink.cn/vued751d13a9cb5376b89cb6719e86f591f3.png';
    //   } else {
    //     this.$emit('imageuploaded', res)
    //     // this.pageForEditing.primary_image_url = res.image.url
    //     // this.$store.commit('setPropImages', { result: res })
    //     this.uploadSuccess = "Image uploaded successfully"
    //   }
    //   // arrow function below ensures that "this" is correct
    //   setTimeout(() => {
    //     this.upLoadingDialog = false
    //   }, 2000);
    // },
    imagechanged() {},
    imageuploading() {
      this.upLoadingDialog = true
    }
  }
}
</script>