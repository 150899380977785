<template>
  <v-app>
    <v-container pt-5>
      <v-layout row>
        <v-breadcrumbs :items="[]"></v-breadcrumbs>
        <v-flex xs12>
          <div class="row align-center justify-center">
            <div class="display-1 pa-2 ma-4 row justify-center" style>
              <p class>Your Real Estate Lead Pages</p>
            </div>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-data-table :headers="headers" :items="boosterPages" class="elevation-1">
            <template slot="headers" slot-scope="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  <v-tooltip v-if="!!header.short" bottom>
                    <span slot="activator">{{ header.short }}</span>
                    <span>{{ header.text }}</span>
                  </v-tooltip>
                  <span v-else>{{ header.text }}</span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  style="cursor: pointer;"
                  @click="goToEdit(item)"
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.slug }}</td>
                  <td>{{ item.page_title }}</td>
                  <!-- <td>
                    <v-btn  icon ripple>
                      <v-icon color="grey lighten-1">edit</v-icon>
                    </v-btn>
                  </td>-->
                </tr>
              </tbody>
            </template>
            <!-- <template slot="items" slot-scope="props">
              <tr style="cursor: pointer;">
                <td class="text-xs-right">{{ props.item.id }}</td>
              </tr>
            </template>-->
          </v-data-table>
        </v-flex>
        <v-flex xs12>
          <v-row justify="center" v-if="unusedSlugs.length > 0" class="pa-5 ma-5">
            <AddPage
              :formSuccess="formSuccess"
              :formSending="formSending"
              :formErrors="formErrors"
              @runAddPage="runAddPage"
            ></AddPage>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import apiService from "@jsroot/services/api-public"
import AddPage from "@jsroot/apps/booster-admin/modal-forms/add-page"
export default {
  components: { AddPage },
  data() {
    return {
      unusedSlugs: [],
      formErrors: [],
      formSending: false,
      formSuccess: "",
      pagination: {
        sortBy: "name",
        rowsPerPage: 1000
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Slug", value: "Slug" },
        {
          text: "Title",
          align: "left"
          // sortable: false,
          // value: "page_title_en"
        }
        // { text: "Canned?", value: "is_canned_search", short: "C?" }
        // { text: 'Iron (%)', value: 'iron' }
      ],
      boosterPages: [{}]
    }
  },
  mounted() {
    apiService().refreshCsrf()
    if (INMOAPP.bsd && INMOAPP.bsd.booster_pages) {
      this.boosterPages = INMOAPP.bsd.booster_pages
    }
    // May 2020 - too much stress passing everything through inmoapp
    // Will start defaulting to below:
    let apiUrl = "/api_admin/v1/booster_pages/list_vue_init"
    apiService()
      .get(apiUrl, {})
      .then(
        response => {
          this.unusedSlugs = response.data.unused_slugs
        },
        err => {
        }
      )
  },
  methods: {
    runAddPage(newPageParams) {
      this.formSending = true
      newPageParams.slug = this.unusedSlugs[0]
      // TODO - allow selection of slug
      let apiUrl = "/api_admin/v1/booster_pages/new"
      apiService()
        .post(apiUrl, { booster_page: newPageParams })
        .then(
          response => {
            this.formSending = false
            this.formSuccess = "Page created successfully"
            window.location.href = response.data.edit_path
          },
          err => {
            this.formSending = false
            if (err.response.data.error_messages) {
              this.formErrors = [err.response.data.error_messages]
            } else {
              this.formErrors = [err]
            }
            console.log(err)
          }
        )
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    goToEdit(page) {
      let newUrl = "/edit/" + page.uuid
      window.location.href = newUrl
      // let params = { name: page.uuid }
      // let targetRouteName = "editBoosterPage"
      // this.$router.push({ name: targetRouteName, params: params })
    }
    // goToView(page) {
    //   let newUrl = "/edit/" + page.uuid
    //   window.location.href = newUrl
    // }
  }
}
</script>