import _ from 'lodash'
export const dirtyChecker = {
  data() {
    return {
      dirtyFields: {},
      hasPendingChanges: false,
    }
  },
  methods: {
    changesCanceled() {
      location.reload()
      // Apr 2020 - kiss - reload to avoid tricks below
      // this.$store.commit('setPropCancelPendingChanges', true)
      // this.$store.commit('setPropHasPendingChanges', false)
    },
    triggerPropUpdate() {
      this.$emit('updateListing')
    },
    updatePendingChanges(fieldDetails) {
      // if (fieldDetails.newValue === fieldDetails.originalValue) {
      // lodash will compare objects as well as strings
      if (_.isEqual(fieldDetails.newValue, fieldDetails.originalValue)) {
        delete this.dirtyFields[fieldDetails.fieldName]
      }
      else {
        this.dirtyFields[fieldDetails.fieldName] = fieldDetails.newValue
      }
      if (Object.keys(this.dirtyFields).length > 0) {
        this.hasPendingChanges = true
      } else {
        this.hasPendingChanges = false
      }
      // this.$store.dispatch('updatePendingPropChanges', fieldDetails)
    },
  }
}